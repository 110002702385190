/* Legal Layout */
.legal-page {
  overflow-x: hidden;
}

/* Legal Header */
.legal-header {
  background: linear-gradient(135deg, #3AD83A 0%, #2FBF2F 100%);
  color: white;
  padding: 80px 0;
  position: relative;
  overflow: hidden;
}

.legal-header::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.05;
  pointer-events: none;
}

.legal-logo {
  height: 80px;
  width: auto;
  margin-bottom: 2rem;
  transition: transform 0.2s ease;
}

.legal-logo:hover {
  transform: scale(1.05);
}

.legal-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  font-family: 'Inter', sans-serif;
}

.legal-subtitle {
  font-size: 1.25rem;
  opacity: 0.9;
  max-width: 600px;
  margin: 0 auto;
}

/* Legal Content */
.legal-content {
  padding: 80px 0;
  background-color: white;
}

.legal-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.terms-content h2 {
  color: #333;
  font-size: 1.8rem;
  margin: 2rem 0 1.5rem;
  font-weight: 600;
}

.terms-content p,
.terms-content address {
  color: #666;
  line-height: 1.8;
  margin-bottom: 1.5rem;
}

.terms-content a {
  color: #3AD83A;
  text-decoration: none;
  transition: color 0.3s ease;
}

.terms-content a:hover {
  color: #2FBF2F;
}

/* Responsive */
@media (max-width: 768px) {
  .legal-logo {
    height: 60px;
    margin-bottom: 1.5rem;
  }
  
  .legal-title {
    font-size: 2.5rem;
  }
  
  .legal-subtitle {
    font-size: 1.1rem;
  }
} 