.navbar {
  border-bottom: 1px solid #eaeaea;
}

.nav-link {
  color: #666;
  font-weight: 500;
  padding: 0.5rem 1rem !important;
  margin: 0 0.2rem;
  border-radius: 4px;
}

.nav-link:hover {
  color: #007bff;
  background-color: #f8f9fa;
}

.nav-link.active {
  color: #007bff;
  background-color: #e8f1ff;
}

.navbar-brand {
  font-weight: 600;
} 