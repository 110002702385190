.merchant-container {
  min-height: 100vh;
  padding: 2rem;
  background-color: #f5f5f5;
}

.merchant-content {
  max-width: 800px;
  margin: 0 auto;
}

.main-title {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.info-section,
.logs-section {
  margin-bottom: 2rem;
}

.info-card,
.logs-card {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.company-name {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.setup-instructions {
  border-top: 1px solid #eee;
  padding-top: 1rem;
  margin-top: 1rem;
}

.signup-link {
  display: block; /* Changed from inline-block to block */
  width: fit-content;
  margin: 1rem auto; /* Centers the button */
  padding: 0.75rem 1.5rem;
  background-color: #2ecc71; /* Green color */
  color: white !important; /* Changed from blue to white */
  text-decoration: none;
  font-weight: 500;
  border-radius: 6px;
  transition: background-color 0.2s ease;
}

.signup-link:hover {
  background-color: #27ae60; /* Darker green on hover */
  text-decoration: none; /* Removed underline on hover */
}

.logs-list {
  list-style: none;
  padding: 0;
}

.log-item {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
  border-bottom: 1px solid #eee;
}

.log-item:last-child {
  border-bottom: none;
}

.log-event {
  font-weight: 500;
}

.log-date {
  color: #666;
}

.actions-section {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

h2 {
  color: #444;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.no-logs {
  text-align: center;
  color: #666;
}
