.customers-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .customers-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .customers-table th,
  .customers-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .customers-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #495057;
  }
  
  .customers-table tr:last-child td {
    border-bottom: none;
  }
  
  .customers-table tr:hover {
    background-color: #f8f9fa;
  }
  
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination select,
  .pagination button {
    padding: 5px 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
  }
  
  .pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  @media screen and (max-width: 600px) {
    .customers-table,
    .customers-table tbody,
    .customers-table tr,
    .customers-table td {
      display: block;
      width: 100%;
      box-sizing: border-box;
    }
  
    .customers-table thead {
      display: none;
    }
  
    .customers-table tr {
      margin-bottom: 15px;
      border: 1px solid #ddd;
    }
  
    .customers-table td {
      text-align: left;
      padding: 8px;
      position: relative;
      padding-left: 50%;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  
    .customers-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
      font-weight: bold;
    }
  }