/* .invoices-container {
  width: 100%;
  overflow-x: auto;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.invoices-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
}

.invoices-table th,
.invoices-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.invoices-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.invoices-table tr:hover {
  background-color: #f5f5f5;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.pagination button {
  padding: 5px 10px;
  margin: 0 5px;
} */

/* @media screen and (max-width: 600px) {
  .invoices-table,
  .invoices-table tbody,
  .invoices-table tr,
  .invoices-table td {
    display: block;
  }

  .invoices-table thead {
    display: none;
  }

  .invoices-table tr {
    margin-bottom: 15px;
    border: 1px solid #ddd;
  }

  .invoices-table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .invoices-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }
} */


.invoices-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  font-family: Arial, sans-serif;
}

.invoices-table th,
.invoices-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.invoices-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #495057;
}

.invoices-table tr:last-child td {
  border-bottom: none;
}

.invoices-table tr:hover {
  background-color: #f8f9fa;
}

.status-pill {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
}

.status-active {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.status-inactive {
  background-color: #ffebee;
  color: #c62828;
}

.payment-status-pill {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
}

.payment-fully-paid {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.payment-partially-paid {
  background-color: #fff3e0;
  color: #ef6c00;
}

.payment-unpaid {
  background-color: #ffebee;
  color: #c62828;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pagination select,
.pagination button {
  padding: 5px 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media screen and (max-width: 600px) {
  .invoices-table,
  .invoices-table tbody,
  .invoices-table tr,
  .invoices-table td {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .invoices-table thead {
    display: none;
  }

  .invoices-table tr {
    margin-bottom: 15px;
    border: 1px solid #ddd;
  }

  .invoices-table td {
    text-align: left;
    padding: 8px;
    position: relative;
    padding-left: 50%;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .invoices-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  .invoices-container {
    padding: 10px;
  }

  .table-responsive {
    overflow-x: hidden;
  }
}
