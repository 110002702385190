/* Blog Layout */
.blog-page {
  overflow-x: hidden;
}

/* Blog Header */
.blog-header {
  background: linear-gradient(135deg, #3AD83A 0%, #2FBF2F 100%);
  color: white;
  padding: 80px 0;
  position: relative;
  overflow: hidden;
}

.blog-header::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.05;
  pointer-events: none;
}

.blog-logo {
  height: 80px;
  width: auto;
  margin-bottom: 2rem;
  transition: transform 0.2s ease;
}

.blog-logo:hover {
  transform: scale(1.05);
}

.blog-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  font-family: 'Inter', sans-serif;
}

.blog-subtitle {
  font-size: 1.25rem;
  opacity: 0.9;
  max-width: 600px;
  margin: 0 auto;
}

/* Blog Content */
.blog-content {
  padding: 80px 0;
  background-color: #f8f9fa;
}

/* Blog Cards */
.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.blog-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0,0,0,0.05);
  border: 1px solid #eaeaea;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0,0,0,0.1);
}

.blog-card-image {
  height: 200px;
  overflow: hidden;
}

.blog-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.blog-card:hover .blog-card-image img {
  transform: scale(1.1);
}

.blog-card-content {
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.blog-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #333;
}

.blog-card-text {
  color: #666;
  margin-bottom: 1.5rem;
  flex-grow: 1;
}

.blog-card-link {
  color: #3AD83A;
  text-decoration: none;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: auto;
}

.blog-card-link:hover {
  color: #2FBF2F;
}

/* Post Detail */
.post-header {
  background: linear-gradient(135deg, #3AD83A 0%, #2FBF2F 100%);
  color: white;
  padding: 60px 0;
  position: relative;
  overflow: hidden;
}

.post-header::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.05;
  pointer-events: none;
}

.post-content {
  background: white;
  padding: 60px 0;
}

.post-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Responsive */
@media (max-width: 768px) {
  .blog-logo {
    height: 60px;
    margin-bottom: 1.5rem;
  }
  
  .blog-title {
    font-size: 2.5rem;
  }
  
  .blog-subtitle {
    font-size: 1.1rem;
  }
  
  .blog-grid {
    grid-template-columns: 1fr;
  }
}

/* Add any missing footer styles */
.footer {
  padding: 80px 0 40px;
  background-color: #f8f9fa;
}

.footer h5 {
  color: #3AD83A;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 0.75rem;
}

.footer-links a {
  color: #666;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #3AD83A;
}

.footer-bottom {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #dee2e6;
} 