.invoice-preview {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.invoice-preview h2 {
  text-align: center;
  color: #333;
}

.clinic-info {
  text-align: center;
  margin-bottom: 20px;
}

.clinic-info h3 {
  margin-bottom: 5px;
}

.clinic-info p {
  margin: 2px 0;
}

.billing-details {
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
}

.billing-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.billing-grid > div {
  margin-bottom: 10px;
}

.billing-grid strong {
  display: block;
  font-size: 0.9em;
  color: #666;
}

.invoice-items table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-items th,
.invoice-items td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.invoice-items th {
  background-color: #f2f2f2;
}

.invoice-items tfoot {
  font-weight: bold;
}

.footer-text {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 20px;
}

.digital-signature {
  border: 1px solid #ddd;
  padding: 10px;
  min-height: 100px;
  margin-bottom: 20px;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.cancel-button,
.print-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button {
  background-color: #f2f2f2;
  color: #333;
}

.print-button {
  background-color: #007bff;
  color: white;
}


.digital-signature {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px;
}

.signature-canvas {
  border: 1px solid #ddd;
  width: 100%;
  height: 200px;
}

.digital-signature button {
  margin-top: 10px;
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #f2f2f2;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.digital-signature button:hover {
  background-color: #e0e0e0;
}