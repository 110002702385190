.batch-info-container {
  max-width: 100%;
  padding: 20px;
}

.batch-carousel {
  width: 100%;
  overflow-x: auto;
  padding: 20px 0;
}

.batch-list {
  display: flex;
  gap: 20px;
}

.batch-card {
  flex: 0 0 300px;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.batch-card h3 {
  margin-top: 0;
  color: #333;
}

.batch-details p {
  margin: 5px 0;
  font-size: 14px;
}

.batch-details strong {
  color: #555;
}

/* Optional: Hide scrollbar for cleaner look */
.batch-carousel::-webkit-scrollbar {
  display: none;
}

.batch-carousel {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
