.homepage {
  overflow-x: hidden;
}

/* Hero Section */
.hero-section {
  background: linear-gradient(135deg, #3AD83A 0%, #2FBF2F 100%);
  color: white;
  padding: 80px 0;
  position: relative;
  overflow: hidden;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.05;
  pointer-events: none;
}

.min-vh-80 {
  min-height: 80vh;
}

.hero-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  font-family: 'Inter', sans-serif;
}

.hero-subtitle {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  opacity: 0.9;
}

.hero-buttons {
  margin-top: 2rem;
}

.hero-logo {
  width: 80%;
  max-width: 500px;
  height: auto;
  filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.1));
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Stats Section */
.stats-section {
  padding: 60px 0;
  background-color: #ffffff;
  border-bottom: 1px solid #eaeaea;
}

.stat-item {
  padding: 20px;
}

.stat-item h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #3AD83A;
  margin-bottom: 0.5rem;
  font-family: 'Inter', sans-serif;
}

.stat-item p {
  font-size: 1.1rem;
  color: #666;
  margin: 0;
}

/* Features Section */
.features-section {
  padding: 80px 0;
  background-color: white;
}

.section-title {
  margin-bottom: 3rem;
  font-weight: 700;
  color: #3AD83A;
}

.feature-card {
  padding: 40px 30px;
  text-align: center;
  border-radius: 12px;
  transition: all 0.3s ease;
  background: white;
  box-shadow: 0 10px 30px rgba(0,0,0,0.05);
  border: 1px solid #eaeaea;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 2.5rem;
  color: #3AD83A;
  margin-bottom: 1.5rem;
  background: #f8f9fa;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
  box-shadow: 0 4px 12px rgba(58, 216, 58, 0.15);
}

.feature-card h3 {
  margin-bottom: 1rem;
  font-weight: 600;
}

/* Blog Section */
.blog-section {
  padding: 80px 0;
  background-color: #ffffff;
  border-top: 1px solid #eaeaea;
}

.blog-card {
  border: none;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0,0,0,0.05);
  border: 1px solid #eaeaea;
}

.blog-card:hover {
  transform: translateY(-5px);
}

.blog-image {
  height: 200px;
  overflow: hidden;
}

.blog-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.blog-card:hover .blog-image img {
  transform: scale(1.1);
}

.read-more {
  color: #3AD83A;
  text-decoration: none;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.read-more:hover {
  color: #2FBF2F;
}

/* CTA Section */
.cta-section {
  padding: 100px 0;
  background: linear-gradient(135deg, #3AD83A 0%, #2FBF2F 100%);
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.cta-section::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.05;
  pointer-events: none;
}

.cta-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.cta-section p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  opacity: 0.9;
}

/* Custom Button Styles */
.btn-dark {
  background: #000000;
  border-color: #000000;
  padding: 12px 30px;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.3s ease;
  color: #ffffff;
}

.btn-dark:hover {
  background: #222222;
  border-color: #222222;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.btn-outline-light {
  padding: 12px 30px;
  font-weight: 500;
  border-radius: 8px;
  border-width: 2px;
  transition: all 0.3s ease;
}

.btn-outline-light:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

/* Trust Badges Section */
.trust-badges {
  padding: 40px 0;
  background: #f8f9fa;
  border-top: 1px solid #eaeaea;
}

.trust-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #666;
  padding: 15px;
  transition: all 0.3s ease;
}

.trust-badge svg {
  height: 40px;
  width: 40px;
}

.trust-badge:hover {
  color: #3AD83A;
  transform: translateY(-2px);
}

/* Footer */
.footer {
  padding: 80px 0 40px;
  background-color: #f8f9fa;
}

.footer h5 {
  color: #3AD83A;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 0.75rem;
}

.footer-links a {
  color: #666;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #3AD83A;
}

.footer-bottom {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #dee2e6;
}

/* Loading Spinner */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3AD83A;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }
  
  .hero-content {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .stat-item {
    margin-bottom: 2rem;
  }
  
  .hero-logo {
    width: 60%;
    margin: 2rem auto;
  }
}

/* Integration Section */
.integration-section {
  background: linear-gradient(135deg, #3AD83A 0%, #2FBF2F 100%);
  color: white;
  padding: 60px 0;
  position: relative;
  overflow: hidden;
}

.integration-section::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.05;
  pointer-events: none;
}

.integration-logo {
  height: 120px;
  max-width: 200px;
  transition: transform 0.3s ease;
}

.payspot-logo {
  height: 100px;
  max-width: 300px;
}

/* Apply white filter only to HubSpot and Clearent logos */
.integration-logo:not([alt="PaySpot"]) {
  filter: brightness(0) invert(1);
}

.integration-logo-text {
  color: white;
  font-weight: 600;
  margin-top: 1rem;
  text-align: center;
  font-size: 1.5rem;
}

.integration-logo:hover {
  transform: scale(1.05);
}

.integration-plus {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  margin-top: 50px;
}

.integration-plus span {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .integration-plus {
    margin-bottom: 60px;
  }
  .integration-equals {
    margin-top: 60px;
  }
}

@media (max-width: 768px) {
  .integration-logo {
    margin: 1rem 0;
  }
  
  .payspot-logo {
    height: 80px;
    margin: 2rem 0;
  }
  
  .integration-plus {
    margin: 0.5rem 0;
  }
} 