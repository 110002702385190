.signature-section {
  margin: 20px 0;
}

.saved-signature {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signature-pad-container {
  width: 100%;
}

.signature-buttons {
  display: flex;
  justify-content: center;
}

.signature-canvas {
  touch-action: none;
}

.signature-pad {
  width: 100%;
  height: 100px;
}

#payment-button {
  margin: 20px auto;
  display: block;
}

.paymentForm input {
  margin-bottom: 10px;
}