.cookie-consent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.cookie-consent-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  max-width: 400px;
  text-align: center;
}

.cookie-consent-modal h2 {
  margin-top: 0;
}

.cookie-consent-modal button {
  margin-top: 20px;
}
