.demo-page {
  padding: 40px 0;
  background: #f8f9fa;
}

.demo-header {
  text-align: center;
  margin-bottom: 2rem;
}

.demo-header h1 a {
  color: #3AD83A;
  text-decoration: none;
  transition: color 0.3s ease;
}

.demo-header h1 a:hover {
  color: #2FBF2F;
}

.demo-meta {
  margin: 1rem 0;
  color: #666;
}

.demo-meta div {
  margin: 0.5rem 0;
}

.demo-meta a {
  color: #3AD83A;
  text-decoration: none;
}

.demo-meta a:hover {
  color: #2FBF2F;
}

.demo-steps {
  max-width: 1000px;
  margin: 0 auto;
}

.demo-step {
  margin: 2rem 0;
}

.demo-step h3 {
  color: #333;
  margin-bottom: 1rem;
}

.demo-step img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.demo-footer {
  text-align: center;
  margin-top: 2rem;
  color: #666;
}

.demo-footer a {
  color: #3AD83A;
  text-decoration: none;
}

.demo-footer a:hover {
  color: #2FBF2F;
}

hr {
  margin: 2rem 0;
  border-color: #dee2e6;
}

@media (max-width: 768px) {
  .demo-page {
    padding: 20px 0;
  }
  
  .demo-header h1 {
    font-size: 2rem;
  }
  
  .demo-step h3 {
    font-size: 1.25rem;
  }
} 